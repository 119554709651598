
<template>
    <div>
        <div class="row">
            <div class="col-lg-offset-2 col-lg-8">
                <div class="box box-primary">
                    <div class="box-body" style="padding-left: 0px; padding-right: 0px">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="col-md-4" :class="{'has-error': $v.formResponses.visible_id.$error}">
                                    <label for="visibleId" class="control-label">Číslo servisního úkonu</label>
                                    <input id="visibleId" class="form-control" v-model="$v.formResponses.visible_id.$model" type="number" readonly="true" ondblclick="this.readOnly='';" onblur="this.readOnly='true';">
                                    <span v-if="!$v.formResponses.visible_id.minValue" class="help-block">Číslo nového úkonu musí být větší jak číslo posledního úkonu: {{ $v.formResponses.visible_id.$params.minValue.min - 1 }}</span>
                                    <span v-else class="help-block">Klikněte dvakrát na pole pro úpravu tohoto čísla.</span>
                                </div>
                                <div class="col-md-4" style="height:80px" :class="{'has-error' : !customerid && canDoValidation }">
                                    <label class="control-label">Zákazník</label>
                                    <div class="input-group" :style="!customerid && canDoValidation ? 'border: 1px solid #dd4b39' : ''">
                                        <div class="input-group-addon">
                                            <i class="fa fa-address-book"></i>
                                        </div>
                                        <Select2 v-model="customerid" :options="minCustomers" id="selectCus" @select="updateCarSelector()" />
                                    </div>
                                    <span v-if="!customerid" class="help-block">Povinný údaj</span>
                                </div>

                                 <div class="col-md-4" :class="{'has-error': $v.formResponses.carid.$error}" style="height:80px">
                                    <label class="control-label">Auto</label>
                                    <div class="input-group" :style="$v.formResponses.carid.$error ? 'border: 1px solid #dd4b39' : ''">
                                        <div class="input-group-addon">
                                            <i class="fa fa-car"></i>
                                        </div>
                                        <Select2 v-model="$v.formResponses.carid.$model" :options="minCars" id="selectCar" @select="setCustomerByCar()" />
                                    </div>
                                    <span v-if="!$v.formResponses.carid.required" class="help-block">Povinný údaj</span>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="col-md-4" :class="{'has-error': $v.formResponses.name.$error}">
                                    <label for="inputName" class="control-label">Název úkonu</label>
                                    <input id="inputName" class="form-control"  v-model="$v.formResponses.name.$model" type="text">
                                    <span v-if="!$v.formResponses.name.required" class="help-block">Povinný údaj</span>
                                    <span v-if="!$v.formResponses.name.maxLength" class="help-block">Může mít maximálně 128 znaků!</span>
                                </div>
                                <div class="col-md-4">
                                    <ul class="nav nav-stacked">
                                        <li><a href="#">Ulice: {{currentCustomerAddress.street}}</a></li>
                                        <li><a href="#">Město: {{currentCustomerAddress.city}}</a></li>
                                    </ul>
                                </div>
                                <div class="col-md-4">
                                    <ul class="nav nav-stacked">
                                        <li><a href="#">VIN: <span v-if="customerid && formResponses.carid">{{currentCar.vin_code}}</span></a></li>
                                        <li><a href="#">SPZ: <span v-if="customerid && formResponses.carid">{{currentCar.licence_plate}}</span></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-12" style="height: 90px">
                                <div class="col-md-4" :class="{'has-error': $v.formResponses.issue_price.$error}">
                                    <label for="issuePrice" class="control-label">Předpokládaná cena zakázky</label>
                                    <input id="issuePrice" class="form-control" type="text" v-model="$v.formResponses.issue_price.$model">
                                     <span v-if="!$v.formResponses.issue_price.minValue" class="help-block">Cena musí být alespoň 1</span>
                                     <span v-if="!$v.formResponses.issue_price.required" class="help-block">Povinný údaj</span>
                                </div>
                                <div class="col-md-4" style="height: 80px"  :class="{'has-error' : !maintainerid && canDoValidation }">
                                    <ul class="nav nav-stacked">
                                        <li><label class="control-label">Řidič</label></li>
                                        <input class="form-control" :value="maintainerid ? currentMaintainer.fullname : ''" id="driverField" type="text" disabled />
                                        <span v-if="!maintainerid" class="help-block">Vyberte nejdříve auto</span>
                                    </ul>
                                </div>
                                <div class="col-md-4">
                                    <ul class="nav nav-stacked">
                                        <li><a href="#">STK: <span v-if="customerid && formResponses.carid">{{convertToStkTime(currentCar.stk_expire)}}</span></a></li>
                                        <li><a href="#">Rok výroby: <span v-if="customerid && formResponses.carid">{{currentCar.year_made}}</span></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-12" style="height: 90px">
                                <div class="col-md-4" :class="{'has-error' : !fromdate && canDoValidation }">
                                    <label class="control-label">Od kdy</label>
                                    <v-date-picker v-model="fromdate" mode="dateTime" is24hr class="flex-grow" :max-date="todate">
                                        <input slot-scope="{ inputProps, inputEvents, inputValue }" :value="inputValue" v-bind="inputProps" v-on="inputEvents" id="fromdate" class="form-control" autocomplete="off">
                                    </v-date-picker>
                                    <span v-if="!fromdate" class="help-block">Povinný údaj</span>
                                </div>
                                <div class="col-md-4">
                                    <ul class="nav nav-stacked">
                                        <li><a href="#">Telefon: <span v-if="maintainerid">{{currentMaintainer.phone}}</span></a></li>
                                        <li><a href="#">Email: <span v-if="maintainerid">{{currentMaintainer.email}}</span></a></li>
                                    </ul>
                                </div>
                                <div class="col-md-4">     
                                    <label for="kilometers" class="control-label">Stav tachometru</label>
                                    <div class="input-group">
                                        <input id="kilometers" class="form-control" v-model="formResponses.kilometers" type="number">
                                        <div class="input-group-addon" style="background-color: #d2d6de">
                                            km
                                        </div>
                                    </div>                
                                </div> 
                                <div class="col-md-4" style="margin-top: 10px">
                                    <label>
                                        <input v-model="stk_check" type="checkbox"> Jedná se o kontrolu STK  <i class="fa fa-question-circle" style="color: lightgray" data-toggle="tooltip" data-placement="top" title="V případě, že přidáte úkon s tímto příznakem, program automaticky vyhodnotí zda zobrazovat upozornění o nadcházející expiraci STK u daného auta. Nedojde však k automatické aktualizaci tohoto data v kartě auta."></i>              
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-12" style="height: 80px">
                                <div class="col-md-4" :class="{'has-error' : !todate && canDoValidation }">
                                    <label class="control-label">Do kdy</label>
                                    <v-date-picker v-model="todate" class="flex-grow" mode="dateTime" is24hr :min-date="fromdate || Date.now()">
                                                <input slot-scope="{ inputProps, inputEvents, inputValue }" v-bind="inputProps" :value="inputValue" v-on="inputEvents" id="todate" class="form-control" autocomplete="off">
                                    </v-date-picker>
                                    <span v-if="!todate" class="help-block">Povinný údaj</span>
                                    <span v-if="Date.parse(fromdate) > Date.parse(todate)" class="help-block">Špatný čas termínu dokončení</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="box-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="col-lg-6">
                                    <div :class="{'form-group': true, 'has-error': $v.formResponses.comment.$error}">
                                        <label for="inputComment" class="control-label">Popis závady zákazníkem</label><span class="pull-right">({{formResponses.comment.length}}/1024)</span>            
                                        <div>
                                            <textarea maxlength="1024" id="inputComment" v-model="$v.formResponses.comment.$model" style="resize: none;" class="form-control" rows="2" placeholder="Komentář k úkonu"></textarea>
                                            <span v-if="!$v.formResponses.comment.maxLength" class="help-block">Může mít maximálně 1024 znaků!</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <label for="total" class="control-label">Částka bez DPH</label>
                                    <input id="total" class="form-control" type="text" :value="formResponses.final_price_no_dph">
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="col-lg-6">
                                    <div :class="{'form-group': true, 'has-error': $v.formResponses.hidden_comment.$error}">
                                        <label for="inputHiddenComment" class="control-label">Skrytý komentář</label><span class="pull-right">({{formResponses.hidden_comment.length}}/1024)</span>
                                        <div>
                                            <textarea maxlength="1024" id="inputHiddenComment" v-model="$v.formResponses.hidden_comment.$model" style="resize: none;" class="form-control" rows="2" placeholder="Tento komentář neuvidí zákazník"></textarea>
                                            <span v-if="!$v.formResponses.hidden_comment.maxLength" class="help-block">Může mít maximálně 1024 znaků!</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <label for="totalDPH" class="control-label">Částka s DPH</label>
                                    <input id="totalDPH" class="form-control" type="text" :value="formResponses.final_price_dph" style="font-weight:bold;font-size:1.2em">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box">
                <div class="row">
                    <div class="col-md-12">
                        <div class="box box-widget widget-user-2">
                            <div class="box-header with-border">
                                <div class="col-md-6" style="padding: 0 5px">
                                    <label for="select-type" class="control-label">Načíst operaci ze seznamu</label>
                                    <Select2 v-model="operation.listOperation" @change="setListOperation()" :options="getListOperationDescriptions()" id="select-type"/>
                                </div>
                            </div>
                            <div class="box-footer">
                                <div class="col-md-12" style="padding: 0;height:100px">
                                    <div class="col-md-2" style="padding: 0 5px">
                                        <label for="number" class="control-label">Číslo operace</label>
                                        <input id="number" class="form-control" type="text" :value="operation.order" style="text-align:right">
                                    </div>
                                    <div class="col-md-3" style="padding: 0 5px">
                                        <div :class="{'has-error': $v.operation.description.$error}">
                                            <label for="name" class="control-label">Název operace</label>
                                            <input id="name" class="form-control" v-model="$v.operation.description.$model" type="text">
                                            <span v-if="!$v.operation.description.required" class="help-block">Povinný údaj</span>
                                            <span v-if="!$v.operation.description.maxLength" class="help-block">Může mít maximálně {{$v.operation.description.$params.maxLength.max}} znaků!</span>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div :class="{'has-error': $v.operation.description.$error}">
                                            <label for="name" class="control-label">Typ</label>
                                            <!-- <input id="name" class="form-control" v-model="$v.operation.description.$model" type="text"> -->
                                            <Select2 v-model="operation.type" :options="['Práce', 'Materiál']"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12" style="padding: 0">
                                    <div v-if="this.operation.type">
                                        <div class="col-md-2" style="padding: 0 5px">
                                            <div :class="{'has-error': $v.operation.amount.$error}">
                                                <label for="amount" class="control-label" v-if="operation.type == 'Materiál'">Množství</label>
                                                <label for="amount" class="control-label" v-else>Doba trvání operace</label>
                                                <input id="amount" class="form-control" v-model="$v.operation.amount.$model" type="number">
                                                <span v-if="!$v.operation.amount.required" class="help-block">Povinný údaj</span>
                                                <span v-if="!$v.operation.amount.decimal" class="help-block">Musí být číslo!</span>
                                                <span v-if="!$v.operation.amount.minValue" class="help-block">Číslo musí být kladné!</span>
                                            </div>
                                        </div>
                                        <div class="col-md-2" style="padding: 0 5px">
                                            <div :class="{'has-error': $v.operation.invoice_price.$error}">
                                                <label for="price" class="control-label" v-if="operation.type == 'Materiál'">Prodejní cena</label>
                                                <label for="price" class="control-label" v-else>Fakturovaná cena</label>
                                                <input type="number" class="form-control" id="price" v-model="$v.operation.invoice_price.$model">
                                                <span v-if="!$v.operation.invoice_price.required" class="help-block">Povinný údaj</span>
                                                <span v-if="!$v.operation.invoice_price.decimal" class="help-block">Musí být číslo!</span>
                                                <span v-if="!$v.operation.invoice_price.minValue" class="help-block">Číslo musí být kladné!</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="col-md-1" style="padding: 0 5px">
                                            <div :class="{'has-error': $v.operation.amount.$error}">
                                                <label for="amount" class="control-label">Čas</label>
                                                <input id="amount" class="form-control" v-model="$v.operation.amount.$model" type="number">
                                                <span v-if="!$v.operation.amount.required" class="help-block">Povinný údaj</span>
                                                <span v-if="!$v.operation.amount.decimal" class="help-block">Musí být číslo!</span>
                                                <span v-if="!$v.operation.amount.minValue" class="help-block">Číslo musí být kladné!</span>
                                            </div>
                                        </div>
                                        <div class="col-md-1" style="padding: 0 5px">
                                            <div :class="{'has-error': $v.operation.invoice_price.$error}">
                                                <label for="price" class="control-label">Hodinová cena</label>
                                                <input type="number" class="form-control" id="price" v-model="$v.operation.invoice_price.$model">
                                                <span v-if="!$v.operation.invoice_price.required" class="help-block">Povinný údaj</span>
                                                <span v-if="!$v.operation.invoice_price.decimal" class="help-block">Musí být číslo!</span>
                                                <span v-if="!$v.operation.invoice_price.minValue" class="help-block">Číslo musí být kladné!</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-1" style="padding: 0 5px">
                                        <div :class="{'has-error': $v.operation.dph.$error}">
                                            <label for="dph" class="control-label">Sazba DPH</label>
                                            <input id="dph" class="form-control" v-model="$v.operation.dph.$model" type="number">
                                            <span v-if="!$v.operation.dph.required" class="help-block">Povinný údaj</span>
                                            <span v-if="!$v.operation.dph.decimal" class="help-block">Musí být číslo!</span>
                                            <span v-if="!$v.operation.dph.minValue" class="help-block">Číslo musí být kladné!</span>
                                        </div>
                                    </div>
                                    <div class="col-md-2" >
                                        <label for="responsible" class="control-label">Mechanik</label>
                                        <Select2 v-model="operation.employee" id="responsible" :options='getEmployees()'/>
                                    </div>
                                    <div class="col-md-1">
                                        <span class="input-group-btn" style="display: block; margin-top: 40%">
                                            <button @click="createOperation()" type="button" class="btn btn-primary btn-flat">Přidat</button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="box-footer">
                                <p><b>Seznam operací</b></p>
                                <table class="table table-hover">
                                    <thead>
                                    <tr>
                                        <th v-for="head in this.headColumns" :key="head.title">{{head.name}}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="operation in this.ops" v-bind:key="operation.order">
                                            <td>{{operation.order}}</td>
                                            <td>{{operation.description}}</td>
                                            <td>{{operation.type == 0 ? "Práce" : "Materiál"}}</td>
                                            <td>{{operation.invoice_price}}</td>
                                            <td>{{operation.dph}}</td>
                                            <td>{{operation.amount}}</td>
                                            <td>{{operation.employee}}</td>
                                            <td><button type="button" class="btn btn-danger btn-sm" @click="deleteOperation(operation.order)">Odstranit záznam</button></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div class="box box-primary">
                    <form role="form">
                    <div class="box-footer">
                        <div @mouseenter="$v.formResponses.$touch();canDoValidation=true">
                            <button @click="submitForm" type="button" class="btn btn-success pull-left" :disabled="$v.formResponses.$invalid || !customerid || !maintainerid || !fromdate || !todate" style="margin-right: 10px">Přidat</button>
                        </div>
                        <div class="checkbox pull-left">
                            <label>
                                <input v-model="dontClosePage" type="checkbox"> Budu přidávat dál                       
                            </label>
                        </div>
                        <button type="button" class="btn btn-default pull-right" @click="closeTab(page.id)">Zrušit</button>
                    </div>
                    </form>
                </div>
            </div>
        </div>     
    </div>
</template>

<script>
const name = 'IssueAdd';

// Store manipulation methods
import { mapGetters, mapActions } from "vuex";

// Slimscroll
import "jquery-slimscroll/jquery.slimscroll.min.js";

// Select2
import Select2 from '../components/pageux/Select2';

// Vuelidate
import { required, maxLength, between, helpers, decimal, minValue, maxValue, numeric } from 'vuelidate/lib/validators';

// import Calendar from 'v-calendar/lib/components/calendar.umd'
// import DatePicker from 'v-calendar/lib/components/date-picker.umd'

//const timeformat = helpers.regex('timeformat', /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)
const timeformat = helpers.regex('timeformat', /([0-2].[0-9]|3.[0-1]) ([0-1]:[0-5][0-9]|2:[0-3][0-9])/)

export default {
    name,
    components: { 
        Select2,
        // Calendar,
        // DatePicker
    },
    data() {
        return {
            page: null,
            dontClosePage: false,
            stk_check: false,
            ops: [],
            opId: -1,
            currentCustomerAddress: {},
            maintainers: {},

            // Values to send
            formResponses: {
                name: null,
                visible_id: '',
                issue_price: 0,
                carid: null,
                comment: '',
                hidden_comment: '',
                invoice_time: null,
                fromtime: null,
                totime: null,
                final_price_no_dph: null,
                final_price_dph: null,
                kilometers: 0,
            },
            operation: {
                listOperation: null,
                order: 1,
                type: 'Práce',
                description: '',
                dph: 21,
                invoice_price: null,
                amount: null,
                price: null,
                employee: null
            },

            customerid: null,
            maintainerid: null,
            car_back: this.$route.params.carid,
            cus_back: this.$route.params.customerid,

            fromdate: null,
            todate: null,
            oldfromtime: null,
            fromtime: null,
            totime: null,
            hideTp1xs: false,
            hideTp2xs: false,
            canDoValidation: false,

            nextVisibleId: 0
        }
    },
    mounted() {
        if (this.$route.params.dates) {
            this.fromdate = this.$route.params.dates.start
            this.todate = this.$route.params.dates.end
        }
    },
    validations() {
        
        return {
            formResponses: {
                issue_price: {
                    numeric,
                    required,
                    minValue: minValue(1)
                },
                name: {
                    required,
                    maxLength: maxLength(128)
                },
                visible_id: {
                    minValue: minValue(this.nextVisibleId)
                },
                carid: {
                    required
                },
                comment: {
                    maxLength: maxLength(1024)
                },
                hidden_comment: {
                    maxLength: maxLength(1024)
                },
                invoice_time: {
                    between: between(0, 999),
                },
                fromdate: {
                    timeformat,
                    // greaterThanToTime: (val) => val.split(":")[0] > this.totime.split(":")[0] || (val.split(":")[0] == this.totime.split(":")[0] && val.split(":")[1] > this.totime.split(":")(1))
                },
                totime: {
                    timeformat,
                    // lowerThanToTime: (val, this) => val.split(":")[0] < this.fromtime.split(":")[0] || ((val.split(":")[0] == this.fromtime.split(":")[0]) && (val.split(":")[1] < this.fromtime.split(":")[1]))
                },
            },
            operation: {
                description: {
                    required,
                    maxLength: maxLength(64)
                },
                dph: {
                    required,
                    decimal,
                    minValue: minValue(0)
                },
                invoice_price: {
                    required,
                    decimal,
                    minValue: minValue(0)
                },
                price: {
                    decimal,
                    minValue: minValue(0)
                },
                amount: {
                    required,
                    decimal,
                    minValue: minValue(0)
                },
            },
        }
    },
    computed: {
        ...mapGetters(["getAllPages", 'minCars', 'allTags', 'allCustomers', 'minCustomers', 'allListOperations', 'employees', 'minMaintainers', 'currentCar', 'currentMaintainer', 'invoiceInfo']),

    },
    methods: {
        ...mapActions(["makeActiveTab", "addTab", "removeTab", 'closeTab', "addIssue", 'fetchMinCars', 'fetchTags', 'addIssue', 'fetchMinCustomers', 'fetchCustomerMinCars', 'makeCurrentIssue',
                       'fetchListOperations', 'fetchIssueNumber', 'fetchEmployees', 'updateListOperation', 'addListOperation', 'fetchCustomers', 'fetchMinMaintainers', 'fetchCar', 'fetchMaintainer', 'fetchInvoiceInfo']),

        toggleTp1xs(){
            this.hideTp1xs = !this.hideTp1xs;
        },
        toggleTp2xs(){
            this.hideTp2xs = !this.hideTp2xs;
        },
        getListOperationDescriptions()
        {
            var arr = []

            for (var i = 0; i < this.allListOperations.length; i++)
            {
                arr.push(this.allListOperations[i].description)
            }

            return arr
        },
        getEmployees()
        {
            var arr = []

            for (var i = 0; i < this.employees.length; i++)
            {
                var employee = this.employees[i]

                arr.push(employee.employee ? employee.fullname : "Servis")
            }

            return arr
        },
        setListOperation()
        {
            var newValues = this.allListOperations.filter((listOperation) => {return listOperation.description == this.operation.listOperation})
            if (newValues.length)
            {
                newValues = newValues[0]
                this.opId = newValues.id
                this.operation.price = newValues.price
                this.operation.description = newValues.description
                this.operation.invoice_price = newValues.invoice_price
                this.operation.dph = newValues.dph
                this.operation.amount = newValues.amount
                this.operation.type = newValues.type == 0 ? 'Práce' : 'Materiál'
            }
        },
        getCurrentTime(s = 0){
            const dt = new Date(Date.now() + 1000 * s);
            const hrs = dt.getHours();
            const min = dt.getMinutes();
            return `${hrs}:${min}`;  
        },
        getDate(t){
            const dt = new Date(t*1000);
            const day = dt.getDate();
            const mth = dt.getMonth() + 1;
            const yrs = dt.getFullYear();
            return `${day}. ${mth}. ${yrs}`;  
        },
        convertToStkTime(date) {
            if (date != null) {
                let d = new Date(date * 1000)
                let year = d.getFullYear()
                let month = d.getMonth() + 1
                return `${year}/${month}`
            }
        },
        async submitForm(e) {            
            e.preventDefault();
            let ok = true

            this.ops.forEach(async op => {
                let employee = op.employee
                let order = op.order
                delete op.employee
                delete op.order
                op.amount = parseFloat(op.amount)
                op.invoice_price = parseFloat(op.invoice_price)
                op.dph = parseInt(op.dph)
                if (op.id != -1) {  // Update current operation
                    const res = await this.updateListOperation(op)
                    if (res.code != "LIST_OPERATION_UPDATED") {
                        this.$alerts.message('Došlo k chybě při úpravě operace.', "", 'error');
                        ok = false
                    }
                }
                else {
                    delete op.id
                    const res = await this.addListOperation(op)
                    if (res.code != "LIST_OPERATION_ADDED") {
                        this.$alerts.message('Došlo k chybě při přidání operace.', "", 'error');
                        ok = false
                    }
                    op.type = op.type == "Práce" ? 0 : 1
                }
                op.employee = employee
                op.order = order
            })
            
            // Set values
            var values = {
                name: this.formResponses.name,
                carid: this.formResponses.carid,
                issue_price: this.formResponses.issue_price,
                tagid: this.ops.length ? 4 : 1,
                comment: this.formResponses.comment,
                hidden_comment: this.formResponses.hidden_comment,
                invoice_time: this.formResponses.invoice_time,
                visible_id: this.formResponses.visible_id,
                maintainerid: this.maintainerid,
                ops: this.ops,
                stk_check: this.stk_check ? 1 : 0,
                kilometers: this.formResponses.kilometers != 0 ? this.formResponses.kilometers : null,
            };
            values.ops.forEach((operation) => {
                this.employees.forEach((employee) => {
                    if (employee.fullname == operation.employee || (!employee.employee && employee.fullname == undefined))
                    {
                        operation.employeeid = employee.accountid
                    }
                })
            })

            values.due_from = Date.parse(this.fromdate)/1000;
            values.due_to = Date.parse(this.todate)/1000;
            
            const res = await this.addIssue(values);

            if (res.msg == "Success."){
                this.$alerts.toast('Záznam úkonu úspěšně vytvořen.', 'success');
                if (this.dontClosePage){
                    // Clear data 
                    this.formResponses.issue_price = 0;
                    this.formResponses.name = "";
                    this.formResponses.comment = "";
                    this.formResponses.hidden_comment = "";
                    this.formResponses.invoice_time = null;
                    this.$nextTick(() => { this.$v.$reset() })
                    this.canDoValidation = false
                }
                else{
                    this.closeTab(this.page.id);
                    this.makeCurrentIssue(res.id);
                }   
                
            }
            else   
                this.$alerts.message('Nastala chyba!', res.msg, 'error');
        },
        updateCarSelector(){
            this.formResponses.carid = null
            this.fetchMinMaintainers(this.customerid).then(r => {
                if (r.mnt.length == 1) {
                    this.maintainerid = r.mnt[0].id
                    this.fetchMaintainer(this.maintainerid)
                }
                else {
                    this.maintainerid = null
                }
            })
            this.fetchCustomerMinCars(this.customerid).then(() => {
                if (this.minCars.length == 1) {
                    this.formResponses.carid = this.minCars[0].id
                    this.fetchCar(this.formResponses.carid).then( () => {
                        this.formResponses.kilometers = this.currentCar.kilometers;
                    })
                }
            })
            this.currentCustomerAddress = this.allCustomers.filter(cus => {return cus.customerid == this.customerid})[0]
        },
        async createOperation()
        {
            if (this.$v.operation.$invalid || this.operation.description.trim() == '')
            {
                this.$v.operation.$touch()
                return null;
            }

            let op = {
                description: this.operation.description,
                invoice_price: this.operation.invoice_price,
                dph: this.operation.dph,
                amount: this.operation.amount,
                price: this.operation.price,
                type: this.operation.type == "Práce" ? 0 : 1,
                order: this.ops.length && this.ops[this.ops.length - 1].order == this.operation.order ? this.operation.order + 1 : this.operation.order,
                employee: this.operation.employee,
                id: this.opId
            }
            
            this.opId = -1

            this.operation.listOperation = null,
            this.operation.type = 'Práce',
            this.operation.description = '',
            this.operation.dph = 21,
            this.operation.invoice_price = null,
            this.operation.amount = null,
            this.operation.price = null
            this.operation.employee = null

            this.ops.push(op);
            this.operation.order = this.ops.length + 1
            this.calculatePrice()

            this.$v.operation.$reset()

        },
        calculatePrice()
        {
            var totalNoDPH = 0, totalWithDPH = 0

            this.ops.forEach((op) => {
                totalNoDPH += Math.round((op.amount * op.invoice_price + Number.EPSILON) * 100 ) / 100
                totalWithDPH += Math.round((op.amount * op.invoice_price * ((op.dph / 100) + 1) + Number.EPSILON) * 100 ) / 100
            })

            this.formResponses.final_price_no_dph = totalNoDPH
            this.formResponses.final_price_dph = totalWithDPH
        },
        deleteOperation(order)
        {
            for (var i = 0; i < this.ops.length; i++)
            {
                if (this.ops[i].order == order)
                {
                    for (var j = i; j < this.ops.length; j++)
                    {
                        this.ops[j].order = this.ops[j].order - 1
                    }

                    this.ops.splice(i, 1)
                    this.operation.order = this.ops.length + 1

                    return
                }
            }
        },

        setCustomerByCar(){
            if (this.customerid != null) {
                this.fetchCar(this.formResponses.carid).then(() => {
                    this.formResponses.kilometers = this.currentCar.kilometers;
                    this.setMaintainer();
                })

                //this.fetchMinMaintainers(this.customerid)
                return null;
            }
            
            this.minCars.forEach(item => {
                if (item.id == this.formResponses.carid){
                    this.customerid = item.customerid;
                    this.fetchCustomerMinCars(item.customerid);
                }
            });
        },

        setMaintainer() {

            if(this.currentCar != null) {

                this.maintainerid = this.currentCar.maintainerid;
                this.fetchMaintainer(this.maintainerid);
                
            }

        },

        // Assigning due value for time period of issue
        fromTimeChanged(event){
            const fromtime = new Date(event).getTime();
            if (!event){
                this.totime = null;
                return 0;
            } else if (!this.oldfromtime && this.totime) {
                this.oldfromtime = fromtime;
                return 0;
            }

            let totime;
            if (!this.totime) {                
                totime = new Date(fromtime + 3600000)
            } else {
                totime = new Date(this.totime).getTime();
                totime = new Date(fromtime + (totime - this.oldfromtime))
            }

            this.oldfromtime = fromtime;
            this.totime = `${totime.getFullYear()}-${this.$helpers.padNum(totime.getMonth() + 1, 2)}-${this.$helpers.padNum(totime.getDate(), 2)} ${this.$helpers.padNum(totime.getHours(), 2)}:${this.$helpers.padNum(totime.getMinutes(), 2)}`
        },
    },
    async created()
    {
        this.formResponses.carid = this.$route.params.carid;
        this.customerid = this.$route.params.customerid;
        this.maintainerid = this.$route.params.maintainerid;
        if(this.maintainerid != null) {
            this.fetchMaintainer(this.maintainerid);
        }
        if(this.$route.params.carid != null) {
            this.fetchCar( this.$route.params.carid).then( () => {
                this.formResponses.kilometers = this.currentCar.kilometers;
            })
        } 

        this.fetchInvoiceInfo();

        this.stk_check = this.$route.params.stk_check;

        this.headColumns = [
            { name: 'Pořadí' },
            { name: 'Popis' },
            { name: 'Typ' },
            { name: 'Prodejní cena/Hodinová cena' },
            { name: 'DPH' },
            { name: 'Fakturovaný čas/Množství' },
            { name: 'Mechanik' },
            { name: '' }
        ]

        this.fetchEmployees()
        this.fetchTags()
        this.fetchMinCustomers()
        this.fetchCustomers().then( () => {
            if (this.$route.params.customerid)
            {   
                this.currentCustomerAddress = this.allCustomers.filter(cus => {return cus.customerid == this.$route.params.customerid})[0]
                this.fetchCustomerMinCars(this.$route.params.customerid).then(() => {
                    if (this.minCars.length == 1) {
                        this.formResponses.carid = this.minCars[0].id
                        this.fetchCar(this.formResponses.carid).then( () => {
                            this.formResponses.kilometers = this.currentCar.kilometers;
                        })
                    }
                }) 
            }
        })
        this.fetchListOperations()
        this.fetchIssueNumber().then((d) => {
            this.formResponses.visible_id = d.issue_number
            this.nextVisibleId = d.issue_number
        })

        this.page = this.getAllPages.filter(page => page.name == name)[0]   
        this.addTab(this.page);
        this.makeActiveTab(this.page);
    }
}
</script>

<style scoped>

a {
    cursor: pointer;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>

<style>
    .field-input[data-v-74507c40]{
        border-radius: 0px
    }

    .select2-container {
        width: 100% !important;
    }

    .box-body .col-md-12 {
        margin-bottom: 10px;
    }

    .box {
        margin-bottom: 10px;
    }
</style>
